:root {
    --input-padding-x: .75rem;
    --input-padding-y: 0.75rem;
  }
  
  html {
    height: 100%;
  }
  
.loginbody {
    min-height: 100vh;
  }

  .header-nav{
    background-color: #233448;
  }
  
  .loginbody {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
  }
  
  .form-signin {
    width: 100%;
    max-width: 420px;
    padding: 15px;
    margin: 0 auto;
  }
  
  .form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .form-label-group > input,
  .form-label-group > label {
    padding: var(--input-padding-y) var(--input-padding-x);
  }
  
  .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #595f64;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
  }
  
  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input:-ms-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::-moz-placeholder {
    color: transparent;
  }
  
  .form-label-group input::placeholder {
    color: transparent;
  }
  
  .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
  }
  
  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
  }
  
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 8px 0;
    background-color: #f5f5f5;
  }

  .header-nav a:hover:not(.btn, .imgLink){
    background-color: #233448;
  }
  .header-nav a:hover:not(.btn, .imgLink, .dropdown-toggle), .header-nav a.active:not(.btn, .imgLink, .dropdown-toggle){
    text-decoration: none;
    background-color: #001733;
    color: white;
  }
  .add {
    background-color: #40c0f0;
    width: 1.5rem;
  }
  button.remove {
    background-color: #f04040;
    width: 1.5rem;
  }

 
  .billItemContainer::-webkit-scrollbar {
    width: 2px;
  }
   
  .billItemContainer::-webkit-scrollbar-track {
    margin-left: 50px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  .billItemContainer::-webkit-scrollbar-thumb {
    background-color: rgb(119, 119, 119);
    outline: 1px solid slategrey;
    border-radius: 10px;
  }

  .sidebar{
    position: absolute;
    left: 0px;
    padding:0 10px 15px;
    min-height: calc(100vh - 60px);
    top: 60px;
    min-width: 90px;
    background-color: #233448;
  }
  .sBtn.active{
    background-color: #2AF598ee !important;
    border: 0;
  }

  .monospace *{
      font-family: 'monospace', monospace;
  }

  @media(max-width:790px){
    .sidebar{
      transform: translateX(-100%);
    }
  }
